@keyframes loader-spin {
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@-webkit-keyframes loader-spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

.xdsoft_datetimepicker {
  -webkit-box-shadow: 0 5px 15px -5px rgba(0, 0, 0, 0.506);
          box-shadow: 0 5px 15px -5px rgba(0, 0, 0, 0.506);
  background: #fff;
  border-bottom: 1px solid #bbb;
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
  border-top: 1px solid #ccc;
  color: #333;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  padding: 8px;
  padding-left: 0;
  padding-top: 2px;
  position: absolute;
  z-index: 9999;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  display: none; }

.xdsoft_datetimepicker.xdsoft_rtl {
  padding: 8px 0 8px 8px; }

.xdsoft_datetimepicker iframe {
  position: absolute;
  left: 0;
  top: 0;
  width: 75px;
  height: 210px;
  background: transparent;
  border: 0; }

.xdsoft_datetimepicker button {
  border: none !important; }

.xdsoft_noselect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none; }

.xdsoft_noselect::-moz-selection {
  background: transparent; }

.xdsoft_noselect::selection {
  background: transparent; }

.xdsoft_noselect::-moz-selection {
  background: transparent; }

.xdsoft_datetimepicker.xdsoft_inline {
  display: inline-block;
  position: static;
  -webkit-box-shadow: none;
          box-shadow: none; }

.xdsoft_datetimepicker * {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding: 0;
  margin: 0; }

.xdsoft_datetimepicker .xdsoft_datepicker, .xdsoft_datetimepicker .xdsoft_timepicker {
  display: none; }

.xdsoft_datetimepicker .xdsoft_datepicker.active, .xdsoft_datetimepicker .xdsoft_timepicker.active {
  display: block; }

.xdsoft_datetimepicker .xdsoft_datepicker {
  width: 224px;
  float: left;
  margin-left: 8px; }

.xdsoft_datetimepicker.xdsoft_rtl .xdsoft_datepicker {
  float: right;
  margin-right: 8px;
  margin-left: 0; }

.xdsoft_datetimepicker.xdsoft_showweeks .xdsoft_datepicker {
  width: 256px; }

.xdsoft_datetimepicker .xdsoft_timepicker {
  width: 58px;
  float: left;
  text-align: center;
  margin-left: 8px;
  margin-top: 0; }

.xdsoft_datetimepicker.xdsoft_rtl .xdsoft_timepicker {
  float: right;
  margin-right: 8px;
  margin-left: 0; }

.xdsoft_datetimepicker .xdsoft_datepicker.active + .xdsoft_timepicker {
  margin-top: 8px;
  margin-bottom: 3px; }

.xdsoft_datetimepicker .xdsoft_monthpicker {
  position: relative;
  text-align: center; }

.xdsoft_datetimepicker .xdsoft_label i, .xdsoft_datetimepicker .xdsoft_prev, .xdsoft_datetimepicker .xdsoft_next, .xdsoft_datetimepicker .xdsoft_today_button {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGQAAAAeCAYAAADaW7vzAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6Q0NBRjI1NjM0M0UwMTFFNDk4NkFGMzJFQkQzQjEwRUIiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6Q0NBRjI1NjQ0M0UwMTFFNDk4NkFGMzJFQkQzQjEwRUIiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDpDQ0FGMjU2MTQzRTAxMUU0OTg2QUYzMkVCRDNCMTBFQiIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDpDQ0FGMjU2MjQzRTAxMUU0OTg2QUYzMkVCRDNCMTBFQiIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PoNEP54AAAIOSURBVHja7Jq9TsMwEMcxrZD4WpBYeKUCe+kTMCACHZh4BFfHO/AAIHZGFhYkBBsSEqxsLCAgXKhbXYOTxh9pfJVP+qutnZ5s/5Lz2Y5I03QhWji2GIcgAokWgfCxNvcOCCGKqiSqhUp0laHOne05vdEyGMfkdxJDVjgwDlEQgYQBgx+ULJaWSXXS6r/ER5FBVR8VfGftTKcITNs+a1XpcFoExREIDF14AVIFxgQUS+h520cdud6wNkC0UBw6BCO/HoCYwBhD8QCkQ/x1mwDyD4plh4D6DDV0TAGyo4HcawLIBBSLDkHeH0Mg2yVP3l4TQMZQDDsEOl/MgHQqhMNuE0D+oBh0CIr8MAKyazBH9WyBuKxDWgbXfjNf32TZ1KWm/Ap1oSk/R53UtQ5xTh3LUlMmT8gt6g51Q9p+SobxgJQ/qmsfZhWywGFSl0yBjCLJCMgXail3b7+rumdVJ2YRss4cN+r6qAHDkPWjPjdJCF4n9RmAD/V9A/Wp4NQassDjwlB6XBiCxcJQWmZZb8THFilfy/lfrTvLghq2TqTHrRMTKNJ0sIhdo15RT+RpyWwFdY96UZ/LdQKBGjcXpcc1AlSFEfLmouD+1knuxBDUVrvOBmoOC/rEcN7OQxKVeJTCiAdUzUJhA2Oez9QTkp72OTVcxDcXY8iKNkxGAJXmJCOQwOa6dhyXsOa6XwEGAKdeb5ET3rQdAAAAAElFTkSuQmCC); }

.xdsoft_datetimepicker .xdsoft_label i {
  opacity: .5;
  background-position: -92px -19px;
  display: inline-block;
  width: 9px;
  height: 20px;
  vertical-align: middle; }

.xdsoft_datetimepicker .xdsoft_prev {
  float: left;
  background-position: -20px 0; }

.xdsoft_datetimepicker .xdsoft_today_button {
  float: left;
  background-position: -70px 0;
  margin-left: 5px; }

.xdsoft_datetimepicker .xdsoft_next {
  float: right;
  background-position: 0 0; }

.xdsoft_datetimepicker .xdsoft_next, .xdsoft_datetimepicker .xdsoft_prev, .xdsoft_datetimepicker .xdsoft_today_button {
  background-color: transparent;
  background-repeat: no-repeat;
  border: 0 none;
  cursor: pointer;
  display: block;
  height: 30px;
  opacity: .5;
  -ms-filter: "alpha(opacity=50)";
  outline: medium none;
  overflow: hidden;
  padding: 0;
  position: relative;
  text-indent: 100%;
  white-space: nowrap;
  width: 20px;
  min-width: 0; }

.xdsoft_datetimepicker .xdsoft_timepicker .xdsoft_prev, .xdsoft_datetimepicker .xdsoft_timepicker .xdsoft_next {
  float: none;
  background-position: -40px -15px;
  height: 15px;
  width: 30px;
  display: block;
  margin-left: 14px;
  margin-top: 7px; }

.xdsoft_datetimepicker.xdsoft_rtl .xdsoft_timepicker .xdsoft_prev, .xdsoft_datetimepicker.xdsoft_rtl .xdsoft_timepicker .xdsoft_next {
  float: none;
  margin-left: 0;
  margin-right: 14px; }

.xdsoft_datetimepicker .xdsoft_timepicker .xdsoft_prev {
  background-position: -40px 0;
  margin-bottom: 7px;
  margin-top: 0; }

.xdsoft_datetimepicker .xdsoft_timepicker .xdsoft_time_box {
  height: 151px;
  overflow: hidden;
  border-bottom: 1px solid #ddd; }

.xdsoft_datetimepicker .xdsoft_timepicker .xdsoft_time_box > div > div {
  background: #f5f5f5;
  border-top: 1px solid #ddd;
  color: #666;
  font-size: 12px;
  text-align: center;
  border-collapse: collapse;
  cursor: pointer;
  border-bottom-width: 0;
  height: 25px;
  line-height: 25px; }

.xdsoft_datetimepicker .xdsoft_timepicker .xdsoft_time_box > div > div:first-child {
  border-top-width: 0; }

.xdsoft_datetimepicker .xdsoft_today_button:hover, .xdsoft_datetimepicker .xdsoft_next:hover, .xdsoft_datetimepicker .xdsoft_prev:hover {
  opacity: 1;
  -ms-filter: "alpha(opacity=100)"; }

.xdsoft_datetimepicker .xdsoft_label {
  display: inline;
  position: relative;
  z-index: 9999;
  margin: 0;
  padding: 5px 3px;
  font-size: 14px;
  line-height: 20px;
  font-weight: bold;
  background-color: #fff;
  float: left;
  width: 182px;
  text-align: center;
  cursor: pointer; }

.xdsoft_datetimepicker .xdsoft_label:hover > span {
  text-decoration: underline; }

.xdsoft_datetimepicker .xdsoft_label:hover i {
  opacity: 1.0; }

.xdsoft_datetimepicker .xdsoft_label > .xdsoft_select {
  border: 1px solid #ccc;
  position: absolute;
  right: 0;
  top: 30px;
  z-index: 101;
  display: none;
  background: #fff;
  max-height: 160px;
  overflow-y: hidden; }

.xdsoft_datetimepicker .xdsoft_label > .xdsoft_select.xdsoft_monthselect {
  right: -7px; }

.xdsoft_datetimepicker .xdsoft_label > .xdsoft_select.xdsoft_yearselect {
  right: 2px; }

.xdsoft_datetimepicker .xdsoft_label > .xdsoft_select > div > .xdsoft_option:hover {
  color: #fff;
  background: #ff8000; }

.xdsoft_datetimepicker .xdsoft_label > .xdsoft_select > div > .xdsoft_option {
  padding: 2px 10px 2px 5px;
  text-decoration: none !important; }

.xdsoft_datetimepicker .xdsoft_label > .xdsoft_select > div > .xdsoft_option.xdsoft_current {
  background: #3af;
  -webkit-box-shadow: #178fe5 0 1px 3px 0 inset;
          box-shadow: #178fe5 0 1px 3px 0 inset;
  color: #fff;
  font-weight: 700; }

.xdsoft_datetimepicker .xdsoft_month {
  width: 100px;
  text-align: right; }

.xdsoft_datetimepicker .xdsoft_calendar {
  clear: both; }

.xdsoft_datetimepicker .xdsoft_year {
  width: 48px;
  margin-left: 5px; }

.xdsoft_datetimepicker .xdsoft_calendar table {
  border-collapse: collapse;
  width: 100%; }

.xdsoft_datetimepicker .xdsoft_calendar td > div {
  padding-right: 5px; }

.xdsoft_datetimepicker .xdsoft_calendar th {
  height: 25px; }

.xdsoft_datetimepicker .xdsoft_calendar td, .xdsoft_datetimepicker .xdsoft_calendar th {
  width: 14.2857142%;
  background: #f5f5f5;
  border: 1px solid #ddd;
  color: #666;
  font-size: 12px;
  text-align: right;
  vertical-align: middle;
  padding: 0;
  border-collapse: collapse;
  cursor: pointer;
  height: 25px; }

.xdsoft_datetimepicker.xdsoft_showweeks .xdsoft_calendar td, .xdsoft_datetimepicker.xdsoft_showweeks .xdsoft_calendar th {
  width: 12.5%; }

.xdsoft_datetimepicker .xdsoft_calendar th {
  background: #f1f1f1; }

.xdsoft_datetimepicker .xdsoft_calendar td.xdsoft_today {
  color: #3af; }

.xdsoft_datetimepicker .xdsoft_calendar td.xdsoft_highlighted_default {
  background: #ffe9d2;
  -webkit-box-shadow: #ffb871 0 1px 4px 0 inset;
          box-shadow: #ffb871 0 1px 4px 0 inset;
  color: #000; }

.xdsoft_datetimepicker .xdsoft_calendar td.xdsoft_highlighted_mint {
  background: #c1ffc9;
  -webkit-box-shadow: #00dd1c 0 1px 4px 0 inset;
          box-shadow: #00dd1c 0 1px 4px 0 inset;
  color: #000; }

.xdsoft_datetimepicker .xdsoft_calendar td.xdsoft_default, .xdsoft_datetimepicker .xdsoft_calendar td.xdsoft_current, .xdsoft_datetimepicker .xdsoft_timepicker .xdsoft_time_box > div > div.xdsoft_current {
  background: #3af;
  -webkit-box-shadow: #178fe5 0 1px 3px 0 inset;
          box-shadow: #178fe5 0 1px 3px 0 inset;
  color: #fff;
  font-weight: 700; }

.xdsoft_datetimepicker .xdsoft_calendar td.xdsoft_other_month, .xdsoft_datetimepicker .xdsoft_calendar td.xdsoft_disabled, .xdsoft_datetimepicker .xdsoft_time_box > div > div.xdsoft_disabled {
  opacity: .5;
  -ms-filter: "alpha(opacity=50)";
  cursor: default; }

.xdsoft_datetimepicker .xdsoft_calendar td.xdsoft_other_month.xdsoft_disabled {
  opacity: .2;
  -ms-filter: "alpha(opacity=20)"; }

.xdsoft_datetimepicker .xdsoft_calendar td:hover, .xdsoft_datetimepicker .xdsoft_timepicker .xdsoft_time_box > div > div:hover {
  color: #fff !important;
  background: #ff8000 !important;
  -webkit-box-shadow: none !important;
          box-shadow: none !important; }

.xdsoft_datetimepicker .xdsoft_calendar td.xdsoft_current.xdsoft_disabled:hover, .xdsoft_datetimepicker .xdsoft_timepicker .xdsoft_time_box > div > div.xdsoft_current.xdsoft_disabled:hover {
  background: #3af !important;
  -webkit-box-shadow: #178fe5 0 1px 3px 0 inset !important;
          box-shadow: #178fe5 0 1px 3px 0 inset !important;
  color: #fff !important; }

.xdsoft_datetimepicker .xdsoft_calendar td.xdsoft_disabled:hover, .xdsoft_datetimepicker .xdsoft_timepicker .xdsoft_time_box > div > div.xdsoft_disabled:hover {
  color: inherit !important;
  background: inherit !important;
  -webkit-box-shadow: inherit !important;
          box-shadow: inherit !important; }

.xdsoft_datetimepicker .xdsoft_calendar th {
  font-weight: 700;
  text-align: center;
  color: #999;
  cursor: default; }

.xdsoft_datetimepicker .xdsoft_copyright {
  color: #ccc !important;
  font-size: 10px;
  clear: both;
  float: none;
  margin-left: 8px; }

.xdsoft_datetimepicker .xdsoft_copyright a {
  color: #eee !important; }

.xdsoft_datetimepicker .xdsoft_copyright a:hover {
  color: #aaa !important; }

.xdsoft_time_box {
  position: relative;
  border: 1px solid #ccc; }

.xdsoft_scrollbar > .xdsoft_scroller {
  background: #ccc !important;
  height: 20px;
  border-radius: 3px; }

.xdsoft_scrollbar {
  position: absolute;
  width: 7px;
  right: 0;
  top: 0;
  bottom: 0;
  cursor: pointer; }

.xdsoft_datetimepicker.xdsoft_rtl .xdsoft_scrollbar {
  left: 0;
  right: auto; }

.xdsoft_scroller_box {
  position: relative; }

.xdsoft_datetimepicker.xdsoft_dark {
  -webkit-box-shadow: 0 5px 15px -5px rgba(255, 255, 255, 0.506);
          box-shadow: 0 5px 15px -5px rgba(255, 255, 255, 0.506);
  background: #000;
  border-bottom: 1px solid #444;
  border-left: 1px solid #333;
  border-right: 1px solid #333;
  border-top: 1px solid #333;
  color: #ccc; }

.xdsoft_datetimepicker.xdsoft_dark .xdsoft_timepicker .xdsoft_time_box {
  border-bottom: 1px solid #222; }

.xdsoft_datetimepicker.xdsoft_dark .xdsoft_timepicker .xdsoft_time_box > div > div {
  background: #0a0a0a;
  border-top: 1px solid #222;
  color: #999; }

.xdsoft_datetimepicker.xdsoft_dark .xdsoft_label {
  background-color: #000; }

.xdsoft_datetimepicker.xdsoft_dark .xdsoft_label > .xdsoft_select {
  border: 1px solid #333;
  background: #000; }

.xdsoft_datetimepicker.xdsoft_dark .xdsoft_label > .xdsoft_select > div > .xdsoft_option:hover {
  color: #000;
  background: #007fff; }

.xdsoft_datetimepicker.xdsoft_dark .xdsoft_label > .xdsoft_select > div > .xdsoft_option.xdsoft_current {
  background: #c50;
  -webkit-box-shadow: #b03e00 0 1px 3px 0 inset;
          box-shadow: #b03e00 0 1px 3px 0 inset;
  color: #000; }

.xdsoft_datetimepicker.xdsoft_dark .xdsoft_label i, .xdsoft_datetimepicker.xdsoft_dark .xdsoft_prev, .xdsoft_datetimepicker.xdsoft_dark .xdsoft_next, .xdsoft_datetimepicker.xdsoft_dark .xdsoft_today_button {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGQAAAAeCAYAAADaW7vzAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6QUExQUUzOTA0M0UyMTFFNDlBM0FFQTJENTExRDVBODYiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6QUExQUUzOTE0M0UyMTFFNDlBM0FFQTJENTExRDVBODYiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDpBQTFBRTM4RTQzRTIxMUU0OUEzQUVBMkQ1MTFENUE4NiIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDpBQTFBRTM4RjQzRTIxMUU0OUEzQUVBMkQ1MTFENUE4NiIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/Pp0VxGEAAAIASURBVHja7JrNSgMxEMebtgh+3MSLr1T1Xn2CHoSKB08+QmR8Bx9A8e7RixdB9CKCoNdexIugxFlJa7rNZneTbLIpM/CnNLsdMvNjM8l0mRCiQ9Ye61IKCAgZAUnH+mU3MMZaHYChBnJUDzWOFZdVfc5+ZFLbrWDeXPwbxIqrLLfaeS0hEBVGIRQCEiZoHQwtlGSByCCdYBl8g8egTTAWoKQMRBRBcZxYlhzhKegqMOageErsCHVkk3hXIFooDgHB1KkHIHVgzKB4ADJQ/A1jAFmAYhkQqA5TOBtocrKrgXwQA8gcFIuAIO8sQSA7hidvPwaQGZSaAYHOUWJABhWWw2EMIH9QagQERU4SArJXo0ZZL18uvaxejXt/Em8xjVBXmvFr1KVm/AJ10tRe2XnraNqaJvKE3KHuUbfK1E+VHB0q40/y3sdQSxY4FHWeKJCunP8UyDdqJZenT3ntVV5jIYCAh20vT7ioP8tpf6E2lfEMwERe+whV1MHjwZB7PBiCxcGQWwKZKD62lfGNnP/1poFAA60T7rF1UgcKd2id3KDeUS+oLWV8DfWAepOfq00CgQabi9zjcgJVYVD7PVzQUAUGAQkbNJTBICDhgwYTjDYD6XeW08ZKh+A4pYkzenOxXUbvZcWz7E8ykRMnIHGX1XPl+1m2vPYpL+2qdb8CDAARlKFEz/ZVkAAAAABJRU5ErkJggg==); }

.xdsoft_datetimepicker.xdsoft_dark .xdsoft_calendar td, .xdsoft_datetimepicker.xdsoft_dark .xdsoft_calendar th {
  background: #0a0a0a;
  border: 1px solid #222;
  color: #999; }

.xdsoft_datetimepicker.xdsoft_dark .xdsoft_calendar th {
  background: #0e0e0e; }

.xdsoft_datetimepicker.xdsoft_dark .xdsoft_calendar td.xdsoft_today {
  color: #c50; }

.xdsoft_datetimepicker.xdsoft_dark .xdsoft_calendar td.xdsoft_highlighted_default {
  background: #ffe9d2;
  -webkit-box-shadow: #ffb871 0 1px 4px 0 inset;
          box-shadow: #ffb871 0 1px 4px 0 inset;
  color: #000; }

.xdsoft_datetimepicker.xdsoft_dark .xdsoft_calendar td.xdsoft_highlighted_mint {
  background: #c1ffc9;
  -webkit-box-shadow: #00dd1c 0 1px 4px 0 inset;
          box-shadow: #00dd1c 0 1px 4px 0 inset;
  color: #000; }

.xdsoft_datetimepicker.xdsoft_dark .xdsoft_calendar td.xdsoft_default, .xdsoft_datetimepicker.xdsoft_dark .xdsoft_calendar td.xdsoft_current, .xdsoft_datetimepicker.xdsoft_dark .xdsoft_timepicker .xdsoft_time_box > div > div.xdsoft_current {
  background: #c50;
  -webkit-box-shadow: #b03e00 0 1px 3px 0 inset;
          box-shadow: #b03e00 0 1px 3px 0 inset;
  color: #000; }

.xdsoft_datetimepicker.xdsoft_dark .xdsoft_calendar td:hover, .xdsoft_datetimepicker.xdsoft_dark .xdsoft_timepicker .xdsoft_time_box > div > div:hover {
  color: #000 !important;
  background: #007fff !important; }

.xdsoft_datetimepicker.xdsoft_dark .xdsoft_calendar th {
  color: #666; }

.xdsoft_datetimepicker.xdsoft_dark .xdsoft_copyright {
  color: #333 !important; }

.xdsoft_datetimepicker.xdsoft_dark .xdsoft_copyright a {
  color: #111 !important; }

.xdsoft_datetimepicker.xdsoft_dark .xdsoft_copyright a:hover {
  color: #555 !important; }

.xdsoft_dark .xdsoft_time_box {
  border: 1px solid #333; }

.xdsoft_dark .xdsoft_scrollbar > .xdsoft_scroller {
  background: #333 !important; }

.xdsoft_datetimepicker .xdsoft_save_selected {
  display: block;
  border: 1px solid #ddd !important;
  margin-top: 5px;
  width: 100%;
  color: #454551;
  font-size: 13px; }

.xdsoft_datetimepicker .blue-gradient-button {
  font-family: "museo-sans", "Book Antiqua", sans-serif;
  font-size: 12px;
  font-weight: 300;
  color: #82878c;
  height: 28px;
  position: relative;
  padding: 4px 17px 4px 33px;
  border: 1px solid #d7d8da;
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0, #fff), color-stop(73%, #f4f8fa));
  background: linear-gradient(to bottom, #fff 0, #f4f8fa 73%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fff', endColorstr='#f4f8fa', GradientType=0); }

.xdsoft_datetimepicker .blue-gradient-button:hover, .xdsoft_datetimepicker .blue-gradient-button:focus, .xdsoft_datetimepicker .blue-gradient-button:hover span, .xdsoft_datetimepicker .blue-gradient-button:focus span {
  color: #454551;
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0, #f4f8fa), color-stop(73%, #FFF));
  background: linear-gradient(to bottom, #f4f8fa 0, #FFF 73%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f4f8fa', endColorstr='#FFF', GradientType=0); }

/* Magnific Popup CSS */
.mfp-bg {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1042;
  overflow: hidden;
  position: fixed;
  background: #0b0b0b;
  opacity: 0.8; }

.mfp-wrap {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1043;
  position: fixed;
  outline: none !important;
  -webkit-backface-visibility: hidden; }

.mfp-container {
  text-align: center;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  padding: 0 8px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box; }

.mfp-container:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle; }

.mfp-align-top .mfp-container:before {
  display: none; }

.mfp-content {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin: 0 auto;
  text-align: left;
  z-index: 1045; }

.mfp-inline-holder .mfp-content,
.mfp-ajax-holder .mfp-content {
  width: 100%;
  cursor: auto; }

.mfp-ajax-cur {
  cursor: progress; }

.mfp-zoom-out-cur, .mfp-zoom-out-cur .mfp-image-holder .mfp-close {
  cursor: -webkit-zoom-out;
  cursor: zoom-out; }

.mfp-zoom {
  cursor: pointer;
  cursor: -webkit-zoom-in;
  cursor: zoom-in; }

.mfp-auto-cursor .mfp-content {
  cursor: auto; }

.mfp-close,
.mfp-arrow,
.mfp-preloader,
.mfp-counter {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
      user-select: none; }

.mfp-loading.mfp-figure {
  display: none; }

.mfp-hide {
  display: none !important; }

.mfp-preloader {
  color: #CCC;
  position: absolute;
  top: 50%;
  width: auto;
  text-align: center;
  margin-top: -0.8em;
  left: 8px;
  right: 8px;
  z-index: 1044; }

.mfp-preloader a {
  color: #CCC; }

.mfp-preloader a:hover {
  color: #FFF; }

.mfp-s-ready .mfp-preloader {
  display: none; }

.mfp-s-error .mfp-content {
  display: none; }

button.mfp-close,
button.mfp-arrow {
  overflow: visible;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
  display: block;
  outline: none;
  padding: 0;
  z-index: 1046;
  -webkit-box-shadow: none;
          box-shadow: none;
  -ms-touch-action: manipulation;
      touch-action: manipulation; }

button::-moz-focus-inner {
  padding: 0;
  border: 0; }

.mfp-close {
  width: 44px;
  height: 44px;
  line-height: 44px;
  position: absolute;
  right: 0;
  top: 0;
  text-decoration: none;
  text-align: center;
  opacity: 0.65;
  padding: 0 0 18px 10px;
  color: #FFF;
  font-style: normal;
  font-size: 28px;
  font-family: Arial, Baskerville, monospace; }

.mfp-close:hover,
.mfp-close:focus {
  opacity: 1; }

.mfp-close:active {
  top: 1px; }

.mfp-close-btn-in .mfp-close {
  color: #333; }

.mfp-image-holder .mfp-close,
.mfp-iframe-holder .mfp-close {
  color: #FFF;
  right: -6px;
  text-align: right;
  padding-right: 6px;
  width: 100%; }

.mfp-counter {
  position: absolute;
  top: 0;
  right: 0;
  color: #CCC;
  font-size: 12px;
  line-height: 18px;
  white-space: nowrap; }

.mfp-arrow {
  position: absolute;
  opacity: 0.65;
  margin: 0;
  top: 50%;
  margin-top: -55px;
  padding: 0;
  width: 90px;
  height: 110px;
  -webkit-tap-highlight-color: transparent; }

.mfp-arrow:active {
  margin-top: -54px; }

.mfp-arrow:hover,
.mfp-arrow:focus {
  opacity: 1; }

.mfp-arrow:before,
.mfp-arrow:after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  position: absolute;
  left: 0;
  top: 0;
  margin-top: 35px;
  margin-left: 35px;
  border: medium inset transparent; }

.mfp-arrow:after {
  border-top-width: 13px;
  border-bottom-width: 13px;
  top: 8px; }

.mfp-arrow:before {
  border-top-width: 21px;
  border-bottom-width: 21px;
  opacity: 0.7; }

.mfp-arrow-left {
  left: 0; }

.mfp-arrow-left:after {
  border-right: 17px solid #FFF;
  margin-left: 31px; }

.mfp-arrow-left:before {
  margin-left: 25px;
  border-right: 27px solid #3F3F3F; }

.mfp-arrow-right {
  right: 0; }

.mfp-arrow-right:after {
  border-left: 17px solid #FFF;
  margin-left: 39px; }

.mfp-arrow-right:before {
  border-left: 27px solid #3F3F3F; }

.mfp-iframe-holder {
  padding-top: 40px;
  padding-bottom: 40px; }

.mfp-iframe-holder .mfp-content {
  line-height: 0;
  width: 100%;
  max-width: 900px; }

.mfp-iframe-holder .mfp-close {
  top: -40px; }

.mfp-iframe-scaler {
  width: 100%;
  height: 0;
  overflow: hidden;
  padding-top: 56.25%; }

.mfp-iframe-scaler iframe {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -webkit-box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
          box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
  background: #000; }

/* Main image in popup */
img.mfp-img {
  width: auto;
  max-width: 100%;
  height: auto;
  display: block;
  line-height: 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding: 40px 0 40px;
  margin: 0 auto; }

/* The shadow behind the image */
.mfp-figure {
  line-height: 0; }

.mfp-figure:after {
  content: '';
  position: absolute;
  left: 0;
  top: 40px;
  bottom: 40px;
  display: block;
  right: 0;
  width: auto;
  height: auto;
  z-index: -1;
  -webkit-box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
          box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
  background: #444; }

.mfp-figure small {
  color: #BDBDBD;
  display: block;
  font-size: 12px;
  line-height: 14px; }

.mfp-figure figure {
  margin: 0; }

.mfp-bottom-bar {
  margin-top: -36px;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  cursor: auto; }

.mfp-title {
  text-align: left;
  line-height: 18px;
  color: #F3F3F3;
  word-wrap: break-word;
  padding-right: 36px; }

.mfp-image-holder .mfp-content {
  max-width: 100%; }

.mfp-gallery .mfp-image-holder .mfp-figure {
  cursor: pointer; }

@media screen and (max-width: 800px) and (orientation: landscape), screen and (max-height: 300px) {
  /**
       * Remove all paddings around the image on small screen
       */
  .mfp-img-mobile .mfp-image-holder {
    padding-left: 0;
    padding-right: 0; }
  .mfp-img-mobile img.mfp-img {
    padding: 0; }
  .mfp-img-mobile .mfp-figure:after {
    top: 0;
    bottom: 0; }
  .mfp-img-mobile .mfp-figure small {
    display: inline;
    margin-left: 5px; }
  .mfp-img-mobile .mfp-bottom-bar {
    background: rgba(0, 0, 0, 0.6);
    bottom: 0;
    margin: 0;
    top: auto;
    padding: 3px 5px;
    position: fixed;
    -webkit-box-sizing: border-box;
            box-sizing: border-box; }
  .mfp-img-mobile .mfp-bottom-bar:empty {
    padding: 0; }
  .mfp-img-mobile .mfp-counter {
    right: 5px;
    top: 3px; }
  .mfp-img-mobile .mfp-close {
    top: 0;
    right: 0;
    width: 35px;
    height: 35px;
    line-height: 35px;
    background: rgba(0, 0, 0, 0.6);
    position: fixed;
    text-align: center;
    padding: 0; } }

@media all and (max-width: 900px) {
  .mfp-arrow {
    -webkit-transform: scale(0.75);
    transform: scale(0.75); }
  .mfp-arrow-left {
    -webkit-transform-origin: 0;
    transform-origin: 0; }
  .mfp-arrow-right {
    -webkit-transform-origin: 100%;
    transform-origin: 100%; }
  .mfp-container {
    padding-left: 6px;
    padding-right: 6px; } }

/** initial setup **/
.nano {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden; }

.nano > .nano-content {
  position: absolute;
  overflow: scroll;
  overflow-x: hidden;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0; }

.nano > .nano-content:focus {
  outline: thin dotted; }

.nano > .nano-content::-webkit-scrollbar {
  display: none; }

.has-scrollbar > .nano-content::-webkit-scrollbar {
  display: block; }

.nano > .nano-pane {
  background: rgba(0, 0, 0, 0.25);
  position: absolute;
  width: 10px;
  right: 0;
  top: 0;
  bottom: 0;
  visibility: hidden\9;
  /* Target only IE7 and IE8 with this hack */
  opacity: .01;
  -webkit-transition: .2s;
  transition: .2s;
  border-radius: 5px; }

.nano > .nano-pane > .nano-slider {
  background: #444;
  background: rgba(0, 0, 0, 0.5);
  position: relative;
  margin: 0 1px;
  border-radius: 3px; }

.nano:hover > .nano-pane, .nano-pane.active, .nano-pane.flashed {
  visibility: visible\9;
  /* Target only IE7 and IE8 with this hack */
  opacity: 0.99; }

html {
  min-width: 320px; }

body {
  background-color: #f3f3f3;
  color: #000000;
  max-width: 1602px;
  min-width: 320px;
  font-family: "AkzidenzGroteskPro", sans-serif;
  font-size: 14px;
  line-height: 1.2em;
  margin: 0 auto;
  -webkit-font-smoothing: antialiased;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  min-height: 100vh; }
  body._adaptive {
    min-width: 320px;
    max-width: none; }

main {
  -webkit-box-flex: 1;
      -ms-flex: 1 0 auto;
          flex: 1 0 auto; }

input,
button {
  font-family: inherit;
  font-size: inherit; }

@font-face {
  font-family: 'AkzidenzGroteskPro';
  src: url("../fonts/AkzidenzGroteskPro-Light.otf");
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'AkzidenzGroteskPro';
  src: url("../fonts/AkzidenzGroteskPro-Regular.otf");
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'AkzidenzGroteskPro';
  src: url("../fonts/AkzidenzGroteskPro-Light.otf");
  font-weight: 400;
  font-style: italic; }

@font-face {
  font-family: 'AkzidenzGroteskPro';
  src: url("../fonts/AkzidenzGroteskPro-Md.otf");
  font-weight: 500;
  font-style: normal; }

@font-face {
  font-family: 'AkzidenzGroteskPro';
  src: url("../fonts/AkzidenzGroteskPro-Bold.otf");
  font-weight: 700;
  font-style: normal; }

.page-container {
  width: 100%;
  margin: 0 auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  min-height: 100vh; }

.page-layout {
  padding-left: 10px;
  padding-right: 10px; }
  @media (min-width: 768px) {
    .page-layout {
      padding-left: 20px;
      padding-right: 20px; } }

.page-title {
  font-size: 16px;
  font-weight: 500;
  margin-top: 0;
  margin-bottom: 23px;
  letter-spacing: 0.3px; }

.page-subtitle {
  line-height: 23px;
  font-weight: 500;
  margin: 24px 0 20px; }
  .page-subtitle span, .page-subtitle img {
    vertical-align: middle; }
  .page-subtitle img {
    margin-right: 5px; }

.content {
  padding-top: 20px;
  background: white;
  padding-bottom: 20px; }
  .content__inner {
    max-width: 450px; }
    .content__inner_size-2 {
      max-width: 540px; }
    .content__inner_size-3 {
      max-width: 600px; }
  .content__group-title {
    color: #273748;
    font-weight: 500;
    margin-top: 20px;
    margin-bottom: 12px;
    font-size: 14px; }
  .content__row {
    font-size: 0; }
  .content__col {
    display: inline-block;
    vertical-align: top;
    width: 100%; }
    @media (min-width: 768px) {
      .content__col {
        margin-right: 2%;
        width: 49%; } }
    .content__col:last-child {
      margin-right: 0; }
  .content__panel {
    border: 1px solid #e7e7e7;
    padding: 14px; }
    .content__panel .form__label {
      padding-right: 10px;
      vertical-align: middle;
      width: 100%; }
      @media (min-width: 992px) {
        .content__panel .form__label {
          width: 22%; } }
    .content__panel .form__field {
      width: 100%; }
      @media (min-width: 992px) {
        .content__panel .form__field {
          width: 78%; } }
    .content__panel > .form__row:last-child {
      margin-bottom: 0; }
    @media (min-width: 768px) {
      .content__panel_right-offset {
        padding-right: 45px; } }

p {
  margin-top: 22px;
  font-size: 14px;
  letter-spacing: 0.2px; }

.link {
  color: black;
  line-height: 23px;
  display: inline-block;
  text-decoration: underline; }
  .link_black {
    color: #333;
    text-decoration: underline; }
  .link_bold {
    font-weight: 500; }
  .link_no-underline {
    text-decoration: none; }

table {
  width: 100%; }

.add-link {
  background: none;
  border: none;
  color: black;
  font-weight: 500;
  line-height: 23px;
  cursor: pointer;
  padding: 0;
  display: inline-block;
  font-size: 14px;
  text-decoration: none;
  position: relative;
  padding-left: 26px; }
  .add-link:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    background-image: url(sprite.png);
    background-position: -26px -65px;
    width: 26px;
    height: 26px; }
  .add-link img, .add-link span {
    display: inline-block;
    vertical-align: middle; }
  .add-link span {
    margin-left: 15px; }
  .add-link_add-user:before {
    background-image: url(sprite.png);
    background-position: 0px -65px;
    width: 26px;
    height: 26px; }
  .add-link_remove-user:before {
    background-image: url(sprite.png);
    background-position: -65px -30px;
    width: 26px;
    height: 27px; }
  .add-link_import-user:before {
    background-image: url(sprite.png);
    background-position: -91px 0px;
    width: 25px;
    height: 23px; }

.nano {
  width: 100%;
  height: 500px; }
  .nano .nano-content {
    padding: 10px; }
  .nano .nano-pane {
    display: block;
    opacity: 1;
    border-radius: 0;
    width: 12px;
    background-color: #e7e7e7; }

.nano > .nano-pane > .nano-slider {
  background-color: #8d9298;
  border-radius: 0; }

.expand__link {
  padding-left: 30px;
  cursor: pointer;
  position: relative; }
  .expand__link:before {
    content: '';
    background-image: url(sprite.png);
    background-position: -86px -91px;
    width: 17px;
    height: 9px;
    position: absolute;
    left: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%); }
  .expand__link_active:before {
    background-image: url(sprite.png);
    background-position: -69px -91px;
    width: 17px;
    height: 9px; }
  .expand__link_no-open {
    background: none;
    cursor: default; }
  .expand__link_filter {
    min-height: 22px;
    line-height: 22px; }
    .expand__link_filter:before {
      background-image: url(sprite.png);
      background-position: -91px -43px;
      width: 20px;
      height: 20px; }
  .expand__link_filter.expand__link_active {
    font-weight: bold;
    margin-bottom: 15px; }

.expand__content {
  display: none; }

.expand__link_active + .expand__content {
  display: block; }

.expand .form__row {
  margin-bottom: 10px; }

.expand__row {
  margin-bottom: 10px; }

.expand__content > .expand__link:first-child {
  margin-left: 15px; }

.expand__content > .expand__content {
  margin-left: 15px; }

.expand__content > .expand__content {
  margin-left: 30px; }

ul {
  padding-left: 17px; }

.tag {
  background-color: #dcddde;
  padding: 3px 5px;
  margin-right: 2px; }
  .tag_bold {
    font-weight: bold; }

.charts {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  overflow: auto;
  height: 0; }
  .charts__col {
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1; }
  .charts_cols {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between; }
  .charts__item {
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
    padding-bottom: 15px;
    -webkit-box-sizing: border-box;
            box-sizing: border-box; }
  .charts_cols .charts__col {
    width: 48%; }
  .charts_cols_33 .charts__col {
    width: 33%; }

.monitoring {
  padding-top: 50px; }

h5 {
  margin-top: 5px;
  margin-bottom: 15px; }

.login-page {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  margin: 0 -20px;
  padding: 80px 20px 0;
  font-size: 16px;
  background: white url(../images/background.png) no-repeat center bottom; }
  @media (min-width: 768px) {
    .login-page {
      font-size: 18px; } }
  .login-page__text {
    margin: 0;
    font-size: 14px;
    letter-spacing: -0.1px; }
    @media (min-width: 768px) {
      .login-page__text {
        font-size: 17px; } }

.icon {
  display: inline-block;
  vertical-align: middle;
  -ms-flex-negative: 0;
      flex-shrink: 0; }
  .icon_logout {
    background-image: url(sprite.png);
    background-position: -20px -91px;
    width: 19px;
    height: 20px; }
  .icon_lk-empty {
    background-image: url(sprite.png);
    background-position: 0px 0px;
    width: 35px;
    height: 35px; }
  .icon_lang {
    background-image: url(sprite.png);
    background-position: -91px -63px;
    width: 20px;
    height: 20px; }
  .icon_house {
    background-image: url(sprite.png);
    background-position: -91px -23px;
    width: 20px;
    height: 20px; }
  .icon_mail {
    background-image: url(sprite.png);
    background-position: -54px -91px;
    width: 15px;
    height: 11px; }
  .icon_all-day {
    background-image: url(sprite.png);
    background-position: 0px -91px;
    width: 20px;
    height: 20px; }
  .icon_minimize {
    background-image: url(sprite.png);
    background-position: -39px -91px;
    width: 15px;
    height: 15px; }
  .icon_prev-arrow {
    background-image: url(sprite.png);
    background-position: -65px -57px;
    width: 19px;
    height: 6px; }
  .icon_next-arrow {
    background-image: url(sprite.png);
    background-position: -91px -83px;
    width: 19px;
    height: 6px; }
  .icon_step-arrow {
    background-image: url(sprite.png);
    background-position: -27px -35px;
    width: 26px;
    height: 30px; }

.btn {
  display: inline-block;
  vertical-align: middle;
  border-radius: 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  min-width: 116px;
  font-size: 14px;
  cursor: pointer;
  -webkit-transition: background 0.3s ease;
  transition: background 0.3s ease;
  text-decoration: none;
  text-align: center;
  border: 1px solid #cecece;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#e6e6e6), to(#fffefe));
  background-image: linear-gradient(180deg, #e6e6e6 0%, #fffefe 100%);
  -webkit-box-shadow: inset 0 1px 0 0 rgba(255, 255, 255, 0.83);
          box-shadow: inset 0 1px 0 0 rgba(255, 255, 255, 0.83);
  color: #474747;
  padding: 6px 19px;
  letter-spacing: 0; }
  .btn .icon {
    margin-left: 15px; }
  .btn:hover {
    background-image: -webkit-gradient(linear, left bottom, left top, from(#e6e6e6), to(#fffefe));
    background-image: linear-gradient(0deg, #e6e6e6 0%, #fffefe 100%);
    color: #474747; }
  .btn + .btn {
    margin-left: 15px; }
  .btn_search {
    border: 1px solid #bfbfbf;
    width: 22px;
    height: 22px;
    padding: 0;
    min-width: 0;
    background: white url(../images/icons/search-small.png) no-repeat center; }
    .btn_search:hover {
      background: white url(../images/icons/search-small.png) no-repeat center; }

.checkbox {
  position: relative;
  cursor: pointer;
  display: inline-block;
  padding-left: 22px;
  min-height: 17px; }
  .checkbox_big {
    padding-left: 36px;
    min-height: 26px; }
  .checkbox__input {
    height: 1px;
    width: 1px;
    position: absolute;
    z-index: -1;
    visibility: hidden; }
  .checkbox__icon {
    height: 17px;
    width: 17px;
    border: 1px solid #aab2bc;
    display: inline-block;
    vertical-align: middle;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    margin-right: 5px;
    position: absolute;
    top: 0;
    left: 0; }
  .checkbox__text {
    display: inline-block;
    vertical-align: middle;
    color: #273748;
    font-size: 14px;
    line-height: 17px; }
  .checkbox_big .checkbox__icon {
    margin-right: 10px;
    width: 26px;
    height: 26px; }
  .checkbox_big .checkbox__text {
    line-height: 26px; }
  input:checked + .checkbox__icon {
    background-image: url(../images/icons/check-only.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 110%; }

.form__label {
  display: inline-block;
  vertical-align: top;
  color: #273748;
  font-size: 14px;
  line-height: 27px;
  padding-right: 10px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 100%; }
  @media (min-width: 768px) {
    .form__label {
      width: 25%; }
      .form__label_size-2 {
        width: 30%; }
      .form__label_size-3 {
        width: 44%; } }

.form__field {
  display: inline-block;
  vertical-align: middle;
  font-size: 14px;
  width: 100%;
  -webkit-box-sizing: border-box;
          box-sizing: border-box; }
  .form__field_offset {
    padding-right: 35px;
    position: relative;
    vertical-align: top; }
  @media (min-width: 768px) {
    .form__field {
      width: 74%; }
      .form__field_offset {
        padding-right: 0; }
      .form__field_size-2 {
        width: 70%; }
      .form__field_size-3 {
        width: 56%; } }
  .form__field-wrap {
    position: relative; }
  .form__field-wrap + .form__field-wrap {
    margin-top: 20px; }

.form__row {
  margin-bottom: 18px;
  position: relative; }
  .form__row .btn + .link {
    vertical-align: middle;
    margin-left: 18px;
    font-size: 14px; }

.form__add-more {
  background-image: url(sprite.png);
  background-position: -26px -65px;
  width: 26px;
  height: 26px;
  width: 26px;
  height: 26px;
  position: absolute;
  top: 1px;
  display: block;
  right: -31px; }

.form__field_offset > .form__add-more {
  right: 0; }

.form__remove-row {
  width: 26px;
  height: 26px;
  position: absolute;
  right: -31px;
  top: 1px;
  display: block;
  background-image: url(sprite.png);
  background-position: -52px -65px;
  width: 26px;
  height: 26px; }

.input {
  width: 100%;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  display: block;
  padding: 0 9px;
  height: 29px;
  background-color: white;
  border: 1px solid #e7e7e7;
  color: #273748;
  font-size: 14px;
  font-weight: 400;
  line-height: 32px; }
  .input:disabled {
    background-color: #f1f2f2;
    border: 1px solid #e7e7e7; }
  .input_style_1 {
    margin: 0;
    height: 22px;
    padding-left: 4px;
    border: 1px solid #bfbfbf;
    margin-right: 15px; }

.textarea {
  padding: 9px;
  width: 100%;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  border: 1px solid #e7e7e7;
  resize: none;
  min-height: 65px;
  font-size: 14px;
  font-family: "AkzidenzGroteskPro", sans-serif; }

.select {
  border: 1px solid #e7e7e7;
  position: relative; }
  .select:before {
    content: '';
    display: block;
    position: absolute;
    right: 5px;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    width: 0;
    height: 0;
    border-top: 5px solid #96a0ab;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    z-index: 0; }

select {
  height: 29px;
  background: transparent;
  position: relative;
  border: none;
  border-radius: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding-left: 9px;
  padding-right: 20px;
  font-family: "AkzidenzGroteskPro", sans-serif;
  width: 100%;
  font-size: 14px;
  z-index: 1; }

select::-ms-expand {
  display: none; }

.data-table {
  background-color: white;
  -webkit-box-shadow: 0 0 4.8px 0.3px rgba(0, 0, 0, 0.06);
          box-shadow: 0 0 4.8px 0.3px rgba(0, 0, 0, 0.06); }
  @media (min-width: 768px) {
    .data-table .add-link + .add-link {
      margin-left: 20px; } }
  .data-table__row {
    border: 1px solid #bcbdbd;
    border-top: none;
    padding: 5px 10px; }
    .data-table__row_no-border {
      border: none; }
    .data-table__row_no-offset {
      padding: 0; }
    .data-table__row_footer {
      font-size: 0; }
    .data-table__row_table-wrap {
      border: 1px solid #bcbdbd;
      border-bottom: none;
      overflow-x: auto;
      -webkit-overflow-scrolling: touch; }
      @media (min-width: 1200px) {
        .data-table__row_table-wrap {
          overflow: visible; } }
    .data-table__row:last-child {
      margin-bottom: 0; }
  .data-table table {
    vertical-align: top;
    max-width: 100%;
    width: 100%;
    border-spacing: 0; }
    .data-table table input {
      border: 1px solid transparent;
      width: 100%;
      padding-left: 5px;
      padding-right: 5px;
      -webkit-box-sizing: border-box;
              box-sizing: border-box;
      height: 26px; }
    .data-table table select {
      height: 26px;
      width: 100%;
      font-size: 12px; }
    .data-table table .select {
      border-color: transparent; }
      .data-table table .select:before {
        display: none; }
  .data-table thead tr {
    border: 1px solid #bcbdbd;
    border-bottom: none; }
  .data-table th {
    text-align: left;
    background-color: #eaeff3;
    border-right: 1px solid #bcbdbd;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    padding: 0 4px;
    height: 46px;
    color: #273748;
    font-weight: 500;
    line-height: 18px; }
    @media (min-width: 1200px) {
      .data-table th {
        padding: 0 10px;
        height: 43px; } }
    .data-table th a {
      color: #273748;
      font-weight: 500;
      line-height: 18px;
      text-decoration: none;
      display: block; }
    .data-table th:last-child {
      border: none; }
  .data-table td {
    height: 46px;
    padding: 0 10px;
    border-top: 1px solid #bcbdbd;
    border-right: 1px solid #bcbdbd;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    color: #333;
    font-size: 12px;
    line-height: 17px; }
    @media (min-width: 1200px) {
      .data-table td {
        height: 36px; } }
    .data-table td:last-child {
      border-right: none; }
  .data-table tbody tr {
    border-left: 1px solid #bcbdbd;
    border-right: 1px solid #bcbdbd; }
    .data-table tbody tr:last-child td {
      border-bottom: 1px solid #bcbdbd; }
  .data-table tbody tr:hover {
    position: relative;
    background-color: #fdefe2;
    border-color: #e86d00; }
    .data-table tbody tr:hover td {
      border-bottom: 1px solid #e86d00 !important;
      border-top: 1px solid #e86d00; }
    .data-table tbody tr:hover input {
      border: 1px solid #e7e7e7; }
    .data-table tbody tr:hover .select {
      border: 1px solid #e7e7e7;
      background: white; }
      .data-table tbody tr:hover .select:before {
        display: block; }
  .data-table__pagination {
    display: inline-block;
    vertical-align: middle;
    width: 100%; }
    @media (min-width: 768px) {
      .data-table__pagination {
        width: 40%; } }
  .data-table__output-info {
    display: inline-block;
    vertical-align: middle;
    width: 100%; }
    @media (min-width: 768px) {
      .data-table__output-info {
        width: 60%;
        text-align: right; } }
  .data-table__controls a {
    display: inline-block;
    width: 30%;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    margin-right: 10px;
    margin-top: 5px;
    vertical-align: middle; }
    .data-table__controls a img {
      max-width: 100%; }
    @media (min-width: 1200px) {
      .data-table__controls a {
        width: auto; } }
    .data-table__controls a:last-child {
      margin-right: 0; }
  .data-table .checkbox__icon {
    margin: 0; }
  .data-table td span + a {
    margin-left: 10px; }

.info-nav {
  color: #777777;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center; }
  .info-nav__item {
    color: #777777;
    font-weight: 300;
    letter-spacing: -0.7px;
    font-size: 14px;
    margin-left: 20px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    white-space: nowrap; }
    .info-nav__item:hover {
      text-decoration: none; }
  .info-nav__icon {
    margin-right: 6px; }

.lang {
  position: relative; }
  .lang__select {
    background: none;
    border: none;
    text-decoration: none;
    font-size: 14px;
    vertical-align: middle;
    color: #e86d00;
    outline: none;
    cursor: pointer;
    padding: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center; }
  .lang__icon {
    margin-right: 6px; }
  .lang__list {
    position: absolute;
    top: 100%;
    width: 100%;
    left: 0;
    text-align: left;
    background: white;
    -webkit-box-shadow: 0 1px 3.88px 0.12px rgba(186, 186, 186, 0.75);
            box-shadow: 0 1px 3.88px 0.12px rgba(186, 186, 186, 0.75);
    display: none;
    margin-top: 10px; }
  .lang_open .lang__list {
    display: block; }
  .lang__item {
    padding: 10px 5px;
    text-decoration: none;
    display: block;
    color: #e86d00;
    -webkit-transition: background-color 0.2s ease, color 0.2s ease;
    transition: background-color 0.2s ease, color 0.2s ease; }
    .lang__item:hover {
      background: lightgray;
      color: white; }

.legend__inner {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding: 6px;
  width: 397px;
  border: 1px solid #bababa;
  background-color: #f6f6f6;
  position: absolute;
  right: 0;
  top: 48px;
  display: none; }
  .legend__inner_size_1 {
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    width: 100%; }
    @media (min-width: 768px) {
      .legend__inner_size_1 {
        width: 80%; } }

.legend__header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin-bottom: 5px; }

.legend__close {
  cursor: pointer;
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg); }

.legend__content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap; }
  .legend__content_block {
    display: block; }

.legend__item {
  width: 18.5%;
  margin: 0;
  margin-right: 1.8%;
  margin-bottom: 14px;
  word-break: break-word;
  font-size: 12px;
  text-align: center; }
  .legend__item:nth-child(5n) {
    margin-right: 0; }
  .legend__item img {
    display: block;
    max-width: 100%; }
  .legend__item figcaption {
    padding: 0 1px; }

.legend_open .legend__open {
  opacity: 0;
  visibility: hidden; }

.legend_open .legend__inner {
  display: block; }

.legend__form {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center; }
  .legend__form input {
    height: 32px;
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
    margin: 0 15px;
    padding: 0 10px; }

.main-footer {
  padding: 17px 0;
  background-image: -webkit-gradient(linear, left bottom, left top, color-stop(0, #f8f8f8), to(#d4d3d3));
  background-image: linear-gradient(0deg, #f8f8f8 0, #d4d3d3 100%);
  -webkit-box-shadow: 0 1px 3.88px rgba(186, 186, 186, 0.75);
          box-shadow: 0 1px 3.88px rgba(186, 186, 186, 0.75); }
  .main-footer__text {
    color: #565656;
    font-size: 12px;
    font-weight: 500; }

.main-header {
  -webkit-box-shadow: 0 1px 3.88px 0.12px rgba(186, 186, 186, 0.75);
          box-shadow: 0 1px 3.88px 0.12px rgba(186, 186, 186, 0.75);
  background: white;
  position: relative; }
  .main-header .page-layout {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap; }
  .main-header__logo {
    font-size: 0;
    vertical-align: middle;
    display: inline-block;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    max-width: 320px;
    margin-right: 20px; }
    .main-header__logo img {
      display: inline-block;
      vertical-align: middle; }
    .main-header__logo span {
      vertical-align: middle;
      font-size: 18px;
      font-weight: 500;
      margin-left: 10px;
      display: none;
      color: #e86d00; }
    @media (min-width: 768px) {
      .main-header__logo .full_name {
        display: inline-block; } }
    @media (max-width: 768px) {
      .main-header__logo .short_name {
        display: inline-block; } }
  .main-header__aside {
    display: inline-block;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    vertical-align: middle;
    text-align: right;
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap; }
  .main-header__langs {
    display: inline-block;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    vertical-align: middle; }
  .main-header__user-nav {
    display: inline-block;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    vertical-align: middle;
    margin-left: 25px; }
    @media (min-width: 768px) {
      .main-header__user-nav {
        margin-left: 54px; } }
  .main-header__info-nav {
    margin-top: 3px;
    margin-bottom: 3px; }
    .main-header__info-nav:last-child {
      margin-right: 0; }

.matrix {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin-top: 10px; }
  .matrix__item {
    border: 1px solid #bababa;
    padding: 5px;
    width: 322px;
    margin-right: 27px;
    margin-bottom: 25px; }
  .matrix__image {
    max-width: 100px;
    display: inline-block;
    margin-right: 15px; }
  .matrix__content_2row .matrix__image {
    max-width: 47px;
    margin-right: 4px; }
  .matrix__title {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 7px; }

.monitoring-page .tabs, .monitoring-page .tabs__content, .monitoring-page .monitoring, .monitoring-page {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1; }

.pagination {
  background-color: white;
  border: 1px solid #bcbdbd;
  display: inline-block;
  vertical-align: top;
  font-size: 0; }
  .pagination__item {
    position: relative;
    border-right: 1px solid #bcbdbd;
    display: inline-block;
    vertical-align: top;
    min-width: 20px;
    height: 20px;
    padding-right: 6px;
    padding-left: 6px;
    text-align: center;
    font-size: 12px;
    font-weight: 400;
    line-height: 22px;
    color: #273748;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    text-decoration: none; }
    .pagination__item_active {
      background-color: #8a95a1;
      color: white; }
    .pagination__item:last-child {
      border-right: none; }
    .pagination__item img {
      position: absolute;
      left: 50%;
      top: 50%;
      -webkit-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%); }

.pagination-nav {
  font-size: 0; }
  .pagination-nav__item {
    width: 26px;
    height: 26px;
    vertical-align: middle;
    display: inline-block;
    margin-right: 5px;
    border: 1px solid #606f7f;
    border-radius: 50%;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    position: relative; }
    .pagination-nav__item:before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%);
      background-image: url(sprite.png);
      background-position: -116px 0px;
      width: 9px;
      height: 10px; }
    .pagination-nav__item:last-child {
      margin-right: 0; }
    .pagination-nav__item:nth-child(2):before {
      background-image: url(sprite.png);
      background-position: -116px -20px;
      width: 5px;
      height: 10px; }
    .pagination-nav__item:nth-child(3):before {
      background-image: url(sprite.png);
      background-position: -116px -10px;
      width: 5px;
      height: 10px; }
    .pagination-nav__item:nth-child(4):before {
      background-image: url(sprite.png);
      background-position: -78px -79px;
      width: 9px;
      height: 10px; }

.output-info__dropdown {
  color: #3d3d3d;
  font-size: 12px;
  line-height: 31px;
  font-weight: 400;
  display: inline-block;
  vertical-align: middle; }

.output-info__select {
  border: none;
  background: transparent;
  color: #3d3d3d;
  display: inline-block;
  margin-left: 10px;
  font-weight: 500; }
  .output-info__select select {
    font-size: 12px;
    padding-right: 12px; }

.output-info__text {
  color: #3d3d3d;
  font-size: 12px;
  line-height: 31px;
  display: inline-block;
  vertical-align: middle;
  margin-left: 10px; }

.output-info__text + .output-info__dropdown {
  margin-left: 30px; }

.popup {
  margin: 0 auto;
  max-width: 564px;
  background-color: white;
  border: 1px solid #cecece;
  -webkit-box-shadow: 0 0 23.4px 8.6px rgba(126, 126, 126, 0.75);
          box-shadow: 0 0 23.4px 8.6px rgba(126, 126, 126, 0.75);
  position: relative;
  width: 100%; }
  .popup_loader {
    display: none;
    max-width: 250px;
    text-align: center;
    z-index: 99999;
    padding: 10px 20px 40px;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    position: fixed;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
    .popup_loader .popup__title {
      margin: 0;
      padding: 0;
      padding-top: 20px;
      margin-bottom: 30px; }
    .popup_loader .mfp-close {
      display: none; }
  .popup_wide {
    max-width: 790px; }
  .popup .mfp-close {
    font-size: 0;
    background-image: url(sprite.png);
    background-position: -52px -65px;
    width: 26px;
    height: 26px;
    margin-top: 10px;
    margin-right: 10px; }
  .popup__header {
    padding: 12px;
    border-bottom: 1px solid #cecece; }
    .popup__header_offset_2 {
      padding: 15px 20px; }
    .popup__header_no-border {
      border-bottom: none; }
  .popup__title {
    margin: 0;
    font-weight: 600;
    font-size: 16px;
    padding-right: 50px; }
  .popup__content {
    padding: 12px; }
    .popup__content_offset_2 {
      padding-top: 35px;
      padding-left: 20px; }
    .popup__content_top-offset_0 {
      padding-top: 0; }
    .popup__content .form__label {
      padding-right: 10px;
      width: 100%; }
      @media (min-width: 768px) {
        .popup__content .form__label {
          width: 167px; } }
    .popup__content .form__field {
      width: 100%; }
      @media (min-width: 768px) {
        .popup__content .form__field {
          width: 67%; } }
    .popup__content .form__row:last-child {
      margin-bottom: 0; }
  .popup__license {
    max-height: 320px;
    background-color: white;
    border: 1px solid #e7e7e7;
    width: 100%;
    padding-left: 20px;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    padding-right: 40px; }
  .popup__inner {
    border: 1px solid #e7e7e7;
    padding: 10px; }
    .popup__inner_style_1 {
      border: 1px solid #dadada;
      background-color: #fcfcfe; }
  .popup .stepper__breadcrumbs {
    margin-top: 0; }
  .popup__loader img {
    display: block;
    margin: 0 auto;
    max-width: 50px; }

.mfp-bg {
  display: none; }

.stepper__step {
  display: none; }
  .stepper__step_active {
    display: block; }

.stepper__breadcrumbs {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  list-style-type: none;
  padding: 0; }

.stepper__item {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  background: #f8d7ba;
  font-size: 12px;
  color: #585551;
  position: relative;
  padding: 7px 10px; }
  .stepper__item_active {
    color: white;
    font-weight: bold;
    background: #e86d00; }
  .stepper__item + .stepper__item:before {
    content: '';
    background-image: url(sprite.png);
    background-position: 0px -35px;
    width: 27px;
    height: 30px;
    width: 25px;
    height: 100%;
    position: absolute;
    left: -25px;
    top: 0;
    display: block;
    z-index: 1; }
  .stepper__item + .stepper__item_active:before {
    background-image: url(sprite.png);
    background-position: -65px 0px;
    width: 26px;
    height: 30px; }
  .stepper__item_active + .stepper__item:before {
    background-image: url(sprite.png);
    background-position: -27px -35px;
    width: 26px;
    height: 30px; }

.stepper__footer {
  padding-top: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center; }

.stepper__button {
  padding: 1px 4px 4px 5px;
  border: 1px solid currentColor;
  letter-spacing: 0.2px;
  text-align: center;
  cursor: pointer;
  color: #e86d00; }
  .stepper__button_prev {
    color: #777777; }
    .stepper__button_prev .icon {
      margin-right: 12px; }
  .stepper__button_next {
    min-width: 122px; }
    .stepper__button_next .icon {
      margin-left: 12px; }
  .stepper__button_finish {
    display: none; }
  .stepper__button + .stepper__button {
    margin-left: 12px; }

.subgroup + .subgroup {
  margin-top: 20px; }

.subgroup__header {
  margin-bottom: 10px;
  font-size: 14px; }

@media (min-width: 768px) {
  .subgroup__footer {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between; } }

.subgroup__text {
  display: inline-block;
  vertical-align: middle;
  font-size: 14px; }

.subgroup__add-link, .subgroup__label {
  display: inline-block;
  vertical-align: middle;
  font-weight: 400;
  font-size: 14px;
  width: 100%;
  margin-bottom: 5px; }
  @media (min-width: 768px) {
    .subgroup__add-link, .subgroup__label {
      width: 140px;
      margin-bottom: 0; } }

.subgroup__row {
  margin-bottom: 20px; }
  .subgroup__row:last-child {
    margin-bottom: 0; }
  @media (min-width: 768px) {
    .subgroup__row {
      margin-bottom: 10px; } }

.subgroup__content {
  margin-top: 10px;
  margin-bottom: 10px;
  height: 100%; }
  .subgroup__content_height-1 {
    max-height: 110px; }
  .subgroup__content_height-2 {
    max-height: 220px; }
  .subgroup__content_inner .nano-content {
    padding-left: 30px; }

.subgroup__pagination {
  display: inline-block;
  vertical-align: middle;
  width: 100%;
  margin-bottom: 10px; }
  @media (min-width: 768px) {
    .subgroup__pagination {
      margin-bottom: 0;
      width: 140px; } }

.subgroup__output-info {
  display: inline-block;
  vertical-align: middle;
  width: 100%;
  margin-left: -10px; }
  @media (min-width: 768px) {
    .subgroup__output-info {
      margin-left: 0;
      text-align: right;
      width: calc(100% - 135px); } }

.subgroup .nano .nano-content {
  padding-left: 0; }

.subgroup__col {
  width: 47%;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding-right: 20px;
  -ms-flex-negative: 0;
      flex-shrink: 0; }
  .subgroup__col_full {
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
    padding-right: 0; }

.tabs__header {
  color: #474747;
  border-bottom: 1px solid #fd8a1e; }

.tabs__row {
  position: relative; }

.tabs__tab {
  display: inline-block;
  color: #474747;
  padding: 12px 15px 11px;
  text-decoration: none;
  font-size: 16px;
  letter-spacing: 0.5px;
  border: 1px solid transparent;
  border-bottom: none; }
  .tabs__tab_active {
    color: #e86d00;
    border-color: currentColor;
    position: relative; }
    .tabs__tab_active:after {
      content: '';
      position: absolute;
      bottom: -1px;
      width: 100%;
      height: 1px;
      background: white;
      left: 0;
      z-index: 1; }

.tabs__content {
  padding-top: 12px; }

.user-nav__main {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-weight: 400;
  font-size: 14px;
  padding-right: 10px;
  letter-spacing: 0.1px;
  position: relative;
  text-decoration: none;
  margin-right: 20px;
  color: #e86d00;
  min-height: 47px; }
  .user-nav__main:before {
    content: '';
    height: 46px;
    border-right: 1px dashed currentColor;
    position: absolute;
    right: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%); }
  @media (min-width: 768px) {
    .user-nav__main {
      padding-right: 25px;
      font-size: 16px; } }

.user-nav__ask {
  font-size: 0;
  display: inline-block;
  vertical-align: middle; }

.user-nav__logout {
  display: inline-block;
  vertical-align: middle;
  font-size: 0;
  width: 20px;
  height: 20px;
  position: relative; }

.user-nav__ask + .user-nav__logout {
  margin-left: 20px; }

.user-nav__avatar {
  vertical-align: middle;
  display: inline-block;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  overflow: hidden; }
  @media (min-width: 570px) {
    .user-nav__avatar {
      margin-right: 14px; } }

.user-nav__name {
  display: none;
  vertical-align: middle;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 30vw;
  text-align: left; }
  @media (min-width: 570px) {
    .user-nav__name {
      display: inline-block;
      max-width: 20vw; } }

.user-nav__empty {
  color: white;
  font-size: 14px; }
  .user-nav__empty a {
    font-weight: 500;
    text-decoration: underline;
    color: white;
    margin-left: 10px; }
  @media (max-width: 576px) {
    .user-nav__empty a,
    .user-nav__empty span {
      display: block; } }

.user-nav__lang {
  margin-right: 40px; }

.tac {
  text-align: center; }

.db {
  display: block; }

.flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex; }

.align-center {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center; }

.space-between {
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between; }

.nowrap {
  white-space: nowrap; }

.breakword {
  word-break: break-all; }

.row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap; }

.col_20 {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 20%; }

.col_25 {
  width: 25%; }

.mb10 {
  margin-bottom: 10px; }

.ph10 {
  padding: 0 10px; }

/*# sourceMappingURL=main.css.map */
